import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'language',
  pure: true,
  standalone: true,
})
export class LanguagePipe implements PipeTransform {
  transform(lang: string | null): string {
    switch (lang) {
      case 'nl':
        return 'Nederlands';
      default:
        return 'English';
    }
  }
}
