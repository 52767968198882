<div [ngClass]="{ 'inline-block': inlineBlock() }" class="ml-4 relative text-left">
  @if (button()) {
    <div>
      <button
        type="button"
        aria-roledescription="Dropdown menu"
        class="appearance-none inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-2 text-sm font-medium focus:outline-none text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300 bg-gray-200 dark:bg-slate-900 border-gray-300 hover:bg-gray-300 dark:border-gray-500 dark:hover:bg-gray-800"
        id="menu-button">
        @if (text()) {
          <span class="duration-0">{{ text() }}</span>
        } @else {
          <span class="duration-0">Options</span>
        }
        <div class="h-5 w-5 -mr-1 ml-2 duration-0">
          @if (menuOpen()) {
            <fast-svg name="icons/chevron-up" size="20" />
          } @else {
            <fast-svg name="icons/chevron-down" size="20" />
          }
        </div>
      </button>
    </div>
  } @else {
    <div>
      <ng-content select="[slot=start]" />
    </div>
  }

  @if (menuOpen()) {
    <div
      @menu
      class="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-200 dark:bg-slate-900 ring-1 ring-gray-300 dark:ring-gray-800 ring-opacity-5 divide-y divide-gray-300 dark:divide-gray-500 focus:outline-none text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300"
      tabindex="-1">
      <ng-container>
        <ng-content />
      </ng-container>
    </div>
  }
</div>
